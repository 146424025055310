import React, {useCallback, useEffect, useState} from 'react';
import {
    Button,
    ButtonGroup,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table
} from 'reactstrap';
import {Link} from 'react-router-dom';
import '../common/Common.css';
import {useFetchData} from "../http/HttpUtil";
import {formatTimestampToDate, formatTimestampToDateTime} from "../common/Utils";
import './BudgetList.css';
import {getBudgetStatus, getTripTypeDictionary} from "./BudgetModel";
import Spinner from "../common/Spinner";
import LinkCustomers from "./LinkCustomers";
import ExpedientModal from "./expedient/ExpedientModal";
import PaginationContainer from "../common/PaginationContainer";

const BudgetList = () => {
    const fetchData = useFetchData();
    const [budgets, setBudgets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [byUser, setByUser] = useState(true);
    const [showLastUpdatedFields, setShowLastUpdatedFields] = useState(false);
    const [pageInfo, setPageInfo] = useState({
        pageNumber: 0,
        pageSize: 20,
        totalPages: 0,
        totalElements: 0,
    });
    const [expandedTravelcatId, setExpandedTravelcatId] = useState(null);
    const initialFilterParams = {
        travelcatId: '',
        title: '',
        tripType: '',
        status: '',
        customers: ''
    }
    const [filterParams, setFilterParams] = useState(initialFilterParams);
    const [showFilters, setShowFilters] = useState(false);
    const [groupedBudgets, setGroupedBudgets] = useState({});
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [isAssignUserModalOpen, setIsAssignUserModalOpen] = useState(false);
    const [inputAssignedUserId, setInputAssignedUserId] = useState(null);
    const [assignUserExpedientId, setAssignUserExpedientId] = useState(null);
    const [assignUserExpedientTravelcatId, setAssignUserExpedientTravelcatId] = useState(null);
    const [showLinkCustomers, setShowLinkCustomers] = useState(false);
    const [linkCustomersExpedientId, setLinkCustomersExpedientId] = useState(null);
    const [linkCustomersExpedientTravelcatId, setLinkCustomersExpedientTravelcatId] = useState(null);
    const [linkCustomersExpedientCustomerIds, setLinkCustomersExpedientCustomerIds] = useState(null);
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
    const [duplicateModalBudget, setDuplicateModalBudget] = useState(null);
    const [duplicateModalDefaultTravelcatId, setDuplicateModalDefaultTravelcatId] = useState(null);

    const getBudgetStatusStyle = (statusKey) => {
        const statusStyles = {
            SENT: {backgroundColor: 'yellow', color: 'black'},
            SOLD: {backgroundColor: 'green', color: 'white'},
            DISCARDED: {backgroundColor: 'red', color: 'white'},
        };

        return statusStyles[statusKey] || {};
    };

    const groupBudgets = useCallback((budgets) => {
        const newGroupedBudgets = {};
        budgets.forEach(budget => {
            if (!newGroupedBudgets[budget.expedient.travelcatId]) {
                newGroupedBudgets[budget.expedient.travelcatId] = [];
            }
            newGroupedBudgets[budget.expedient.travelcatId].push(budget);
        });
        setGroupedBudgets(newGroupedBudgets);
    }, []);

    const fetchBudgets = useCallback((pageNumber, filters) => {
        const queryParams = new URLSearchParams();
        queryParams.append('page', pageNumber);
        queryParams.append('size', pageInfo.pageSize);

        function addCustomQueryParams() {
            for (const key in filters) {
                if (filters[key]) {
                    queryParams.append(key, filters[key]);
                }
            }
            if (byUser) {
                queryParams.append('byUser', 'true');
            }
        }

        addCustomQueryParams();

        const fetchUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/budget/resources?${queryParams.toString()}`;
        fetchData(fetchUrl, {})
            .then(data => {
                setBudgets(data.content);
                groupBudgets(data.content);
                setPageInfo((p) => ({
                    ...p,
                    pageNumber: data.pageable.pageNumber,
                    totalPages: data.totalPages,
                    totalElements: data.totalElements,
                }));
                setLoading(false);
            });
    }, [byUser, pageInfo.pageSize, groupBudgets, fetchData]);

    useEffect(() => {
        setLoading(true);
        fetchBudgets(pageInfo.pageNumber, null);
    }, [pageInfo.pageNumber, fetchBudgets, byUser, groupBudgets]);

    const handlePageChange = (newPage) => {
        setPageInfo((p) => ({
            ...p,
            pageNumber: newPage,
        }));
    };

    const handleConfirmDuplicate = async (budget) => {
        const confirmDuplicate = window.confirm('¿Estás seguro de que quieres duplicar este presupuesto?');

        if (confirmDuplicate) {
            await fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/budget/duplicate/${budget.id}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }).then(() => window.location.reload());
        }
    }

    const remove = async (id) => {
        const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar este presupuesto?');

        if (confirmDelete) {
            await fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/budget/${id}`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }).then(() => {
                let updatedBudgets = [...budgets].filter(i => i.id !== id);
                setBudgets(updatedBudgets);
                groupBudgets(updatedBudgets);
            });
        }
    }

    if (loading) {
        return <Spinner/>;
    }

    const handleRowClick = (travelcatId) => {
        setExpandedTravelcatId(travelcatId === expandedTravelcatId ? null : travelcatId);
        if (!expandedTravelcatId) {
            setOpenDropdownId(null);
        }
    };

    const handleApplyFilters = () => {
        setLoading(true);
        fetchBudgets(0, filterParams);
    };

    const handleClearFilters = () => {
        setLoading(true);
        setFilterParams(initialFilterParams)
        fetchBudgets(0, null);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleApplyFilters();
        }
    };

    const handleToggleDropdown = (budgetId) => {
        if (openDropdownId === budgetId) {
            setOpenDropdownId(null);
        } else {
            setOpenDropdownId(budgetId);
        }
    };

    const handleOpenAssignUserModal = (expedient) => {
        setAssignUserExpedientId(expedient.id);
        setAssignUserExpedientTravelcatId(expedient.travelcatId);
        setIsAssignUserModalOpen(true);
    };

    const handleCloseAssignUserModal = () => {
        setInputAssignedUserId(null);
        setAssignUserExpedientId(null);
        setAssignUserExpedientTravelcatId(null);
        setIsAssignUserModalOpen(false);
    };

    const handleAssignUserFormSubmit = async () => {
        const confirmAssign = window.confirm(`¿Estás seguro de que quieres asignar el expediente ${assignUserExpedientTravelcatId} a ${inputAssignedUserId}?`);

        if (confirmAssign) {
            await fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/expedient/${assignUserExpedientId}/assign`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({assignedUserId: inputAssignedUserId}),
            }).then(() => handleCloseAssignUserModal())
                .then(() => window.location.reload());
        }
    };

    function getAssignUserIdModal() {
        return <Modal isOpen={isAssignUserModalOpen} toggle={handleCloseAssignUserModal}>
            <ModalHeader toggle={handleCloseAssignUserModal}>
                Asignar Usuario
            </ModalHeader>
            <ModalBody>
                <form onSubmit={handleAssignUserFormSubmit}>
                    <FormGroup>
                        <Label>Asigna un usuario al expediente {assignUserExpedientTravelcatId}</Label>
                    </FormGroup>
                    <FormGroup>
                        <Label>Email:</Label>
                        <Input
                            type="text"
                            id="email"
                            value={inputAssignedUserId}
                            onChange={(e) => setInputAssignedUserId(e.target.value)}
                            required
                            autoComplete="email"
                        />
                    </FormGroup>
                    <ModalFooter>
                        <Button color="success" type="submit" disabled={!inputAssignedUserId}>
                            Asignar
                        </Button>
                        <Button color="danger" onClick={handleCloseAssignUserModal}>
                            Cancelar
                        </Button>
                    </ModalFooter>
                </form>
            </ModalBody>
        </Modal>;
    }

    function getLinkCustomersModal() {
        return <Modal isOpen={showLinkCustomers} toggle={() => handleCloseLinkCustomers()}
                      size="xl">
            <ModalBody>
                <LinkCustomers
                    expedientId={linkCustomersExpedientId}
                    travelcatId={linkCustomersExpedientTravelcatId}
                    initialCustomerIds={linkCustomersExpedientCustomerIds}
                    onClose={() => handleCloseLinkCustomers()}/>
            </ModalBody>
            <ModalFooter>
                <Button color="danger"
                        onClick={() => handleCloseLinkCustomers()}>Cerrar</Button>
            </ModalFooter>
        </Modal>;
    }

    function getDuplicateModal() {
        return <ExpedientModal
            isOpen={isDuplicateModalOpen}
            toggle={handleCloseDuplicateModal}
            onConfirm={handleConfirmDuplicate}
            budget={duplicateModalBudget}
            defaultTravelcatId={duplicateModalDefaultTravelcatId}
        />;
    }

    const handleShowLinkCustomers = (expedient) => {
        setLinkCustomersExpedientId(expedient.id);
        setLinkCustomersExpedientTravelcatId(expedient.travelcatId);
        setLinkCustomersExpedientCustomerIds(expedient.customers?.map(customer => customer.id) || []);
        setShowLinkCustomers(true);
    };

    const handleCloseLinkCustomers = () => {
        setLinkCustomersExpedientId(null);
        setLinkCustomersExpedientTravelcatId(null);
        setLinkCustomersExpedientCustomerIds(null);
        setShowLinkCustomers(false);
    };

    const handleOpenDuplicateModal = (budget) => {
        setDuplicateModalBudget(budget);
        setDuplicateModalDefaultTravelcatId(budget?.expedient.travelcatId);
        setIsDuplicateModalOpen(true);
    };
    const handleCloseDuplicateModal = () => {
        setDuplicateModalBudget(null);
        setDuplicateModalDefaultTravelcatId(null);
        setIsDuplicateModalOpen(false);
    };

    const budgetList = Object.entries(groupedBudgets).map(([travelcatId, budgets]) => {
        const titles = budgets.map(budget => budget.title).join(', ');
        const isExpandable = budgets.length > 1;

        return (
            <React.Fragment key={travelcatId}>
                <tr onClick={() => isExpandable && handleRowClick(travelcatId)}>
                    <td>
                        {isExpandable && (
                            <button
                                className="expand-button"
                                aria-label={expandedTravelcatId ? 'Collapse' : 'Expand'}
                                tabIndex="0"
                                style={{color: expandedTravelcatId === travelcatId ? 'blue' : 'black'}}
                            >
                                {expandedTravelcatId === travelcatId ? '-' : '+'}
                            </button>
                        )}
                        {isExpandable ? travelcatId : (
                            <Link to={`/budgets/${budgets[0].id}/details`}>
                                {travelcatId}
                            </Link>
                        )}
                    </td>
                    <td>{titles}</td>
                    <td>{getTripTypeDictionary()[budgets[0].tripType]}</td>
                    <td>{formatTimestampToDate(budgets[0].deliveryDate)}</td>
                    <td>{formatTimestampToDate(budgets[0].departureDate)}</td>
                    <td style={getBudgetStatusStyle(budgets[0].status)}>{getBudgetStatus()[budgets[0].status]}</td>
                    <td>{budgets[0].customers}</td>
                    <td>{budgets[0].expedient.assignedUserId}</td>
                    <td>{budgets[budgets.length - 1].createdBy}</td>
                    <td>{formatTimestampToDateTime(budgets[budgets.length - 1].creationTimestamp)}</td>
                    {showLastUpdatedFields && (
                        <>
                            <td>{budgets[0].lastUpdatedByAll}</td>
                            <td>{formatTimestampToDateTime(budgets[0].lastUpdatedTimestampAll)}</td>
                        </>
                    )}
                    <td>
                        {(!isExpandable && (
                            <div>
                                <Dropdown isOpen={openDropdownId === budgets[0].id}
                                          toggle={() => handleToggleDropdown(budgets[0].id)}>
                                    <DropdownToggle caret size="sm" color="warning">Más</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={Link} to={`/plannings/${budgets[0].expedient.id}`}>
                                            Planificación
                                        </DropdownItem>
                                        <DropdownItem onClick={() => handleOpenAssignUserModal(budgets[0].expedient)}>
                                            Asignar usuario
                                        </DropdownItem>
                                        <DropdownItem onClick={() => handleShowLinkCustomers(budgets[0].expedient)}>
                                            Vincular clientes
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to={`/budgets/${budgets[0].id}`}>
                                            Editar
                                        </DropdownItem>
                                        <DropdownItem onClick={() => handleOpenDuplicateModal(budgets[0])}>
                                            Duplicar
                                        </DropdownItem>
                                        <DropdownItem onClick={() => remove(budgets[0].id)}>
                                            Eliminar
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        )) || (
                            <Dropdown
                                isOpen={!expandedTravelcatId && openDropdownId === budgets[0].id}
                                toggle={() => handleToggleDropdown(budgets[0].id)}>
                                <DropdownToggle caret size="sm" color="warning">Más</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag={Link} to={`/plannings/${budgets[0].expedient.id}`}>
                                        Planificación
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleOpenAssignUserModal(budgets[0].expedient)}>
                                        Asignar usuario
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleShowLinkCustomers(budgets[0].expedient)}>
                                        Vincular clientes
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        )}
                    </td>
                </tr>
                {expandedTravelcatId === travelcatId && budgets.map(budget => (
                    <tr key={budget.id}>
                        <td>
                            <Link to={`/budgets/${budget.id}/details`} style={{paddingLeft: '10px'}}>
                                {travelcatId}
                            </Link>
                        </td>
                        <td>{budget.title}</td>
                        <td>{getTripTypeDictionary()[budget.tripType]}</td>
                        <td>{formatTimestampToDate(budget.departureDate)}</td>
                        <td>{formatTimestampToDate(budget.deliveryDate)}</td>
                        <td style={getBudgetStatusStyle(budget.status)}>{getBudgetStatus()[budget.status]}</td>
                        <td>{budget.customers}</td>
                        <td>{budget.expedient.assignedUserId}</td>
                        <td>{budget.createdBy}</td>
                        <td>{formatTimestampToDateTime(budget.creationTimestamp)}</td>
                        {showLastUpdatedFields && (
                            <>
                                <td>{budget.lastUpdatedByAll}</td>
                                <td>{formatTimestampToDateTime(budget.lastUpdatedTimestampAll)}</td>
                            </>
                        )}
                        <td>
                            <Dropdown
                                isOpen={openDropdownId === budget.id}
                                toggle={() => handleToggleDropdown(budget.id)}>
                                <DropdownToggle caret size="sm" color="warning">Más</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag={Link} to={`/budgets/${budget.id}`}>
                                        Editar
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleOpenDuplicateModal(budget)}>
                                        Duplicar
                                    </DropdownItem>
                                    <DropdownItem onClick={() => remove(budget.id)}>
                                        Eliminar
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </td>
                    </tr>
                ))}
            </React.Fragment>
        );
    });

    const handleCheckboxChange = () => {
        setByUser(!byUser);
    };

    function handleShowLastUpdatedFieldsCheckboxChange() {
        setLoading(true);

        const newShowLastUpdatedFields = !showLastUpdatedFields;
        setShowLastUpdatedFields(newShowLastUpdatedFields);

        const updatedFilterParams = {
            ...filterParams,
            showLastUpdatedFields: newShowLastUpdatedFields
        };

        fetchBudgets(pageInfo.pageNumber, updatedFilterParams);
    }

    return (
        <div>
            <Container fluid>
                {showFilters ? (
                    <div className="d-flex justify-content-between align-items-center">
                        <h3>Expedientes</h3>
                        <div className="d-flex">
                            <Input
                                type="checkbox"
                                id="byUserCheckbox"
                                checked={byUser}
                                onChange={handleCheckboxChange}
                            />
                            <Label for="byUserCheckbox" className="ms-2">
                                Mis presupuestos
                            </Label>
                        </div>
                        <FormGroup className="d-flex">
                            <Input
                                type="text"
                                placeholder="Expediente"
                                value={filterParams.travelcatId}
                                style={{
                                    width: '100px',
                                    flexBasis: `${Math.max(filterParams.travelcatId.length, 10)}ch`
                                }}
                                onChange={(e) => setFilterParams(prevFilterParams => ({
                                    ...prevFilterParams,
                                    travelcatId: e.target.value
                                }))}
                                autoComplete="travelcatId"
                                onKeyDown={handleKeyDown}
                            />
                            <Input
                                type="text"
                                placeholder="Destino"
                                value={filterParams.title}
                                style={{width: '100px', flexBasis: `${Math.max(filterParams.title.length, 10)}ch`}}
                                onChange={(e) => setFilterParams(prevFilterParams => ({
                                    ...prevFilterParams,
                                    title: e.target.value
                                }))}
                                autoComplete="title"
                                onKeyDown={handleKeyDown}
                            />
                            <Input
                                type="select"
                                name="tripType"
                                id="tripType"
                                value={filterParams.tripType}
                                onChange={(e) => setFilterParams(prevFilterParams => ({
                                    ...prevFilterParams,
                                    tripType: e.target.value
                                }))}
                                style={{width: '100px', flexBasis: `${Math.max(filterParams.tripType.length, 10)}ch`}}
                            >
                                <option value="">Tipo</option>
                                {Object.entries(getTripTypeDictionary()).map(([key, value]) => (
                                    <option key={key} value={key}>
                                        {value}
                                    </option>
                                ))}
                            </Input>
                            <Input
                                type="select"
                                name="status"
                                id="status"
                                value={filterParams.status}
                                onChange={(e) => setFilterParams(prevFilterParams => ({
                                    ...prevFilterParams,
                                    status: e.target.value
                                }))}
                                style={{width: '100px', flexBasis: `${Math.max(filterParams.status.length, 10)}ch`}}
                            >
                                <option value="">Estado</option>
                                {Object.entries(getBudgetStatus()).map(([key, value]) => (
                                    <option key={key} value={key} style={getBudgetStatusStyle(key)}>
                                        {value}
                                    </option>
                                ))}
                            </Input>
                            <Input
                                type="text"
                                placeholder="Cliente(s)"
                                value={filterParams.customers}
                                style={{
                                    width: '100px',
                                    flexBasis: `${Math.max(filterParams.customers.length, 10)}ch`
                                }}
                                onChange={(e) => setFilterParams(prevFilterParams => ({
                                    ...prevFilterParams,
                                    customers: e.target.value
                                }))}
                                autoComplete="customers"
                                onKeyDown={handleKeyDown}
                            />
                            <ButtonGroup>
                                <Button color="warning" onClick={() => handleApplyFilters()}>Aplicar filtros</Button>
                                <Button color="secondary" onClick={() => handleClearFilters()}>Reiniciar</Button>
                                <Button color="success" onClick={() => setShowFilters(!showFilters)}>Esconder
                                    filtros</Button>
                            </ButtonGroup>
                        </FormGroup>
                        <div className="d-flex">
                            <Button color="primary" tag={Link} to="/budgets/new">Añadir presupuesto</Button>
                        </div>
                    </div>
                ) : (
                    <div className="d-flex justify-content-between align-items-center">
                        <h3>Expedientes</h3>
                        <div className="d-flex">
                            <Input
                                type="checkbox"
                                id="byUserCheckbox"
                                checked={byUser}
                                onChange={handleCheckboxChange}
                            />
                            <Label for="byUserCheckbox" className="ms-2">
                                Mis presupuestos
                            </Label>
                        </div>
                        <div className="d-flex">
                            <Button color="success" onClick={() => setShowFilters(!showFilters)}>
                                Filtros
                            </Button>
                        </div>
                        <div className="float-end">
                            <Button color="primary" tag={Link} to="/budgets/new">Añadir presupuesto</Button>
                        </div>
                    </div>
                )}
                <br/>
                <div className="d-flex align-content-center float-end">
                    <Input
                        type="checkbox"
                        id="showLastUpdatedFieldsCheckbox"
                        checked={showLastUpdatedFields}
                        onChange={handleShowLastUpdatedFieldsCheckboxChange}
                    />
                    <Label for="showLastUpdatedFieldsCheckbox" className="ms-2">
                        Ver últimas modificaciones
                    </Label>
                </div>
                <Table className="mt-4">
                    <thead>
                    <tr>
                        <th width="8%">Expediente</th>
                        <th width="10%">Destino</th>
                        <th width="8%">Tipo de viaje</th>
                        <th width="8%">Fecha de entrega</th>
                        <th width="8%">Fecha de salida</th>
                        <th width="8%">Estado</th>
                        <th width="10%">Cliente(s)</th>
                        <th width="6%">Usuario asignado</th>
                        <th width="6%">Creado por</th>
                        <th width="8%">Creado el</th>
                        {showLastUpdatedFields && (
                            <>
                                <th width="6%">Modificado por</th>
                                <th width="6%">Modificado el</th>
                            </>
                        )}
                        <th width="6%">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {budgetList}
                    </tbody>
                </Table>
                <PaginationContainer pageInfo={pageInfo} handlePageChange={handlePageChange}/>
            </Container>
            {getAssignUserIdModal()}
            {showLinkCustomers && getLinkCustomersModal()}
            {isDuplicateModalOpen && getDuplicateModal()}
        </div>
    );
};

export default BudgetList;
