import React, {useState} from 'react';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {useFetchData} from "../../http/HttpUtil";
import PropTypes from "prop-types";

function ExpedientModal({isOpen, toggle, onConfirm, budget, defaultTravelcatId}) {
    const fetchData = useFetchData();
    const [travelcatId, setTravelcatId] = useState(defaultTravelcatId || '');
    const [updatedBudget, setUpdatedBudget] = useState(budget || '');
    const [travelcatLocationId, setTravelcatLocationId] = useState('B');

    const handleTravelcatIdChange = (event) => {
        const {value} = event.target;
        setTravelcatId(value);
        setUpdatedBudget(prevState => ({
            ...prevState,
            expedient: {
                travelcatId: value,
            },
        }));
    };

    const handleTravelcatLocationIdChange = (event) => {
        setTravelcatLocationId(event.target.value);
    };

    const handleGenerateExpedient = async () => {
        const data = await fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/expedient/generate/${travelcatLocationId}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        setUpdatedBudget({
            ...updatedBudget,
            expedient: {
                ...updatedBudget.expedient,
                id: data.id,
                travelcatId: data.travelcatId,
            },
        });
    };

    const handleValidateExpedient = async () => {
        if (!updatedBudget?.expedient?.travelcatId) {
            window.alert('Introduce un valor en Expediente');
            return;
        }

        const data = await fetchData(
            `${process.env.REACT_APP_BACKEND_BASE_URL}/api/expedient/travelcatId/${updatedBudget.expedient.travelcatId}`,
            {}
        );

        if (data) {
            setUpdatedBudget({
                ...updatedBudget,
                expedient: {
                    ...updatedBudget.expedient,
                    id: data.id,
                    travelcatId: data.travelcatId,
                    caseOpening: data.caseOpening,
                },
            });
        }
    };

    const handleConfirm = () => {
        onConfirm(updatedBudget);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="l">
            <ModalHeader toggle={toggle}>Duplicar presupuesto</ModalHeader>
            <ModalBody>
                <FormGroup className="row align-items-center">
                    <Label for="travelcatId">Expediente</Label>
                    <div className="col-md-3">
                        <Input
                            type="text"
                            name="expedient.travelcatId"
                            id="travelcatId"
                            value={updatedBudget.expedient.travelcatId || travelcatId}
                            onChange={handleTravelcatIdChange}
                            autoComplete="travelcatId"
                            required
                        />
                    </div>
                    <div className="col-md-2">
                        <Button
                            color="primary"
                            onClick={handleValidateExpedient}
                            disabled={updatedBudget.expedient.travelcatId === defaultTravelcatId}
                        >
                            Validar
                        </Button>
                    </div>
                    <div className="col-md-3">
                        <Button color="primary"
                                onClick={handleGenerateExpedient}
                        >
                            Generar
                        </Button>
                    </div>
                    <div className="col-md-4">
                        <Input
                            type="select"
                            name="travelcatLocationId"
                            id="travelcatLocationId"
                            value={travelcatLocationId}
                            onChange={handleTravelcatLocationIdChange}
                        >
                            <option value="B">Barcelona</option>
                            <option value="V">Valencia</option>
                            <option value="M">Madrid</option>
                        </Input>
                    </div>
                    <div className="col-md-12 mt-3">
                        <Button color="success"
                                onClick={handleConfirm}
                                disabled={!updatedBudget.expedient.id}
                        >
                            Confirmar
                        </Button>
                    </div>
                </FormGroup>
            </ModalBody>
        </Modal>
    );
}

ExpedientModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    budget: PropTypes.object.isRequired,
    defaultTravelcatId: PropTypes.string.isRequired
};

export default ExpedientModal;
