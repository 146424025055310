import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {useFetchData} from "../http/HttpUtil";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getExpedientModel} from "./ExpedientModel";

const PlanningEdit = () => {
    const fetchData = useFetchData();
    const initialFormState = getExpedientModel();

    const [expedient, setExpedient] = useState(initialFormState);
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        if (id !== 'new') {
            fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/expedient/${id}`, {})
                .then(data => setExpedient(data));
        }
    }, [id, setExpedient, fetchData]);

    function toISODate(value) {
        return value ? new Date(value).toISOString().split('T')[0] : '';
    }

    const handleChange = (event) => {
        const {name, value, type, checked} = event.target;

        setExpedient((prevState) => {
            const updatedState = {...prevState};

            const [parent, nested] = name.split('.');
            if (type === 'checkbox') {
                updatedState[parent] = {
                    ...prevState[parent],
                    [nested]: checked,
                };
            } else if (type === 'date') {
                updatedState[parent] = {
                    ...prevState[parent],
                    [nested]: toISODate(value),
                };
            } else {
                updatedState[name] = value;
            }

            return updatedState;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        await fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/expedient`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(expedient),
        });

        setExpedient(initialFormState);
        navigate('/plannings');
    };

    return (
        <div>
            <Container>
                <h2>Editar planificación - {expedient.travelcatId}</h2>
                <br/>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6}>
                            <h3>PRE-VENTA</h3>
                            <br/>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="callAndSend">Llamada y envío</Label>
                                        <Input
                                            className="input-date"
                                            type="date"
                                            name="caseTracking.callAndSend"
                                            id="callAndSend"
                                            value={expedient.caseTracking.callAndSend || ''}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="expectedDelivery">Entrega prevista</Label>
                                        <Input
                                            className="input-date"
                                            type="date"
                                            name="caseTracking.expectedDelivery"
                                            id="expectedDelivery"
                                            value={expedient.caseTracking.expectedDelivery || ''}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="sentToClient">Envío cliente</Label>
                                        <Input
                                            className="input-date"
                                            type="date"
                                            name="caseTracking.sentToClient"
                                            id="sentToClient"
                                            value={expedient.caseTracking.sentToClient || ''}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check className="label-checkbox">
                                            Email
                                            <Input
                                                className="input-checkbox"
                                                type="checkbox"
                                                name="caseTracking.email"
                                                checked={expedient.caseTracking.email}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check className="label-checkbox">
                                            Whatsapp
                                            <Input
                                                className="input-checkbox"
                                                type="checkbox"
                                                name="caseTracking.whatsApp"
                                                checked={expedient.caseTracking.whatsApp}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="tracking">Seguimiento</Label>
                                        <Input
                                            className="input-date"
                                            type="date"
                                            name="caseTracking.tracking"
                                            id="tracking"
                                            value={expedient.caseTracking.tracking || ''}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="salesAppointment">Cita de venta</Label>
                                        <Input
                                            className="input-date"
                                            type="date"
                                            name="caseTracking.salesAppointment"
                                            id="salesAppointment"
                                            value={expedient.caseTracking.salesAppointment || ''}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check className="label-checkbox">
                                            Pedir cambios
                                            <Input
                                                className="input-checkbox"
                                                type="checkbox"
                                                name="caseTracking.askAmendments"
                                                checked={expedient.caseTracking.askAmendments}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="expectedDeliveryAfterAmendments">Entrega prevista</Label>
                                        <Input
                                            className="input-date"
                                            type="date"
                                            name="caseTracking.expectedDeliveryAfterAmendments"
                                            id="expectedDeliveryAfterAmendments"
                                            value={expedient.caseTracking.expectedDeliveryAfterAmendments || ''}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="updateSentToClient">Envío actualización</Label>
                                        <Input
                                            className="input-date"
                                            type="date"
                                            name="caseTracking.updateSentToClient"
                                            id="updateSentToClient"
                                            value={expedient.caseTracking.updateSentToClient || ''}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check className="label-checkbox">
                                            Email planificación
                                            <Input
                                                className="input-checkbox"
                                                type="checkbox"
                                                name="caseTracking.emailAmendment"
                                                checked={expedient.caseTracking.emailAmendment}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check className="label-checkbox">
                                            Whatsapp planificación
                                            <Input
                                                className="input-checkbox"
                                                type="checkbox"
                                                name="caseTracking.whatsAppAmendment"
                                                checked={expedient.caseTracking.whatsAppAmendment}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check className="label-checkbox">
                                            Correo reserva
                                            <Input
                                                className="input-checkbox"
                                                type="checkbox"
                                                name="caseTracking.bookingEmail"
                                                checked={expedient.caseTracking.bookingEmail}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check className="label-checkbox">
                                            Abrir ficha post-venta
                                            <Input
                                                className="input-checkbox"
                                                type="checkbox"
                                                name="caseTracking.openPostSalesFile"
                                                checked={expedient.caseTracking.openPostSalesFile}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <h3>POST-VENTA</h3>
                            <br/>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Correo reserva
                                            <Input
                                                type="checkbox"
                                                name="postSales.mailReservation"
                                                checked={expedient.postSales.mailReservation}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Comp. pago
                                            <Input
                                                type="checkbox"
                                                name="postSales.payment"
                                                checked={expedient.postSales.payment}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            DNI/Pasaporte
                                            <Input
                                                type="checkbox"
                                                name="postSales.idPass"
                                                checked={expedient.postSales.idPass}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Whatsapp reserva confirmada
                                            <Input
                                                type="checkbox"
                                                name="postSales.whatsAppReservationConfirmed"
                                                checked={expedient.postSales.whatsAppReservationConfirmed}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Itinerario-Recibo-Contrato-etc
                                            <Input
                                                type="checkbox"
                                                name="postSales.itineraryReceiptAgreementEtc"
                                                checked={expedient.postSales.itineraryReceiptAgreementEtc}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Agendar
                                            <Input
                                                type="checkbox"
                                                name="postSales.schedule"
                                                checked={expedient.postSales.schedule}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Firma contrato
                                            <Input
                                                type="checkbox"
                                                name="postSales.agreementSignature"
                                                checked={expedient.postSales.agreementSignature}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Cita planificación
                                            <Input
                                                type="checkbox"
                                                name="postSales.planningAppointment"
                                                checked={expedient.postSales.planningAppointment}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Checklist posterior
                                            <Input
                                                type="checkbox"
                                                name="postSales.laterChecklist"
                                                checked={expedient.postSales.laterChecklist}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br/>
                            <br/>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Entrega documentación
                                            <Input
                                                type="checkbox"
                                                name="postSales.documentationDelivery"
                                                checked={expedient.postSales.documentationDelivery}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Revisión documentación personal
                                            <Input
                                                type="checkbox"
                                                name="postSales.personalDocumentationReview"
                                                checked={expedient.postSales.personalDocumentationReview}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Revisión restricciones país
                                            <Input
                                                type="checkbox"
                                                name="postSales.countryRestrictionReview"
                                                checked={expedient.postSales.countryRestrictionReview}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Enviar Whatsapp enlace restricciones
                                            <Input
                                                type="checkbox"
                                                name="postSales.sentWhatsAppRestrictionsLink"
                                                checked={expedient.postSales.sentWhatsAppRestrictionsLink}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Planificación
                                            <Input
                                                type="checkbox"
                                                name="postSales.planning"
                                                checked={expedient.postSales.planning}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Tramitación moneda
                                            <Input
                                                type="checkbox"
                                                name="postSales.currencyExchange"
                                                checked={expedient.postSales.currencyExchange}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Tramitación internet
                                            <Input
                                                type="checkbox"
                                                name="postSales.networkData"
                                                checked={expedient.postSales.networkData}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Cálculo gastos
                                            <Input
                                                type="checkbox"
                                                name="postSales.costCalculation"
                                                checked={expedient.postSales.costCalculation}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Telf. emergencia
                                            <Input
                                                type="checkbox"
                                                name="postSales.emergencyPhone"
                                                checked={expedient.postSales.emergencyPhone}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Guía restaurantes
                                            <Input
                                                type="checkbox"
                                                name="postSales.restaurantsGuidebook"
                                                checked={expedient.postSales.restaurantsGuidebook}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <br/>
                            <br/>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Enviar documentación digital
                                            <Input
                                                type="checkbox"
                                                name="postSales.sendDigitalDocumentation"
                                                checked={expedient.postSales.sendDigitalDocumentation}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Enviar planificación definitiva + excursiones con precio
                                            <Input
                                                type="checkbox"
                                                name="postSales.sendFinalPlanningAndActivitiesWithPrices"
                                                checked={expedient.postSales.sendFinalPlanningAndActivitiesWithPrices}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Liquidar pagos viaje
                                            <Input
                                                type="checkbox"
                                                name="postSales.settleTripPayments"
                                                checked={expedient.postSales.settleTripPayments}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Liquidar pagos excursones, visados, etc
                                            <Input
                                                type="checkbox"
                                                name="postSales.settleActivitiesVisaEtcPayments"
                                                checked={expedient.postSales.settleActivitiesVisaEtcPayments}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Entrega bonos
                                            <Input
                                                type="checkbox"
                                                name="postSales.bonusesDelivery"
                                                checked={expedient.postSales.bonusesDelivery}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Pago
                                            <Input
                                                type="checkbox"
                                                name="postSales.payEverything"
                                                checked={expedient.postSales.payEverything}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup check>
                                        <Label check>
                                            Rembolsar servicios de pago en destino
                                            <Input
                                                type="checkbox"
                                                name="postSales.refundPaymentServicesAtDestination"
                                                checked={expedient.postSales.refundPaymentServicesAtDestination}
                                                onChange={handleChange}
                                            />
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <FormGroup>
                        <Button color="success" type="submit">Guardar</Button>{' '}
                        <Button color="danger" tag={Link} to="/plannings">Cancelar</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    )
        ;
};

export default PlanningEdit;
