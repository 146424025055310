import React, {useEffect, useState} from 'react';
import {useFetchBlob, useFetchData} from "../http/HttpUtil";
import PropTypes from "prop-types";
import Spinner from "../common/Spinner";
import {Button, ButtonGroup, Input, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader} from "reactstrap";
import '../common/ModalFullScreen.css';

const MAX_FILE_SIZE_MB = 5;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

function Attachment({entity, id, onClose}) {
    const fetchData = useFetchData();
    const fetchBlob = useFetchBlob();
    const [files, setFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                await fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/attachment/list/${entity}?id=${id}`, {})
                    .then(data => setFiles(data));
            } catch (error) {
                console.error('Error fetching files: ', error);
            }
        };

        fetchFiles().then(() => setLoading(false));

    }, [entity, id, fetchData]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const oversizedFiles = files.filter(file => file.size > MAX_FILE_SIZE);

        if (oversizedFiles.length > 0) {
            const message = oversizedFiles.length === 1 ?
                `El fichero no puede tener más de ${MAX_FILE_SIZE_MB}MB` :
                `Los ficheros no pueden tener más de ${MAX_FILE_SIZE_MB}MB`;
            setErrorMessage(message);
            setSelectedFiles(null);
        } else {
            setErrorMessage('');
            setSelectedFiles(event.target.files);
        }
    };

    const handleUpload = async () => {
        try {
            const formData = new FormData();

            Array.from(selectedFiles).forEach((file) => formData.append(`files`, file));

            fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/attachment/upload/${entity}/${id}`, {
                method: 'POST',
                body: formData,
            }).then(() => {
                setSelectedFiles(null);
                window.location.reload();
            });
        } catch (error) {
            console.error('Error uploading file: ', error);
        }
    };

    const handleDelete = async (path) => {
        const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar este archivo?');
        if (confirmDelete) {
            try {
                await fetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/attachment/delete?path=${path}`, {
                    method: 'DELETE'
                }).then(() => window.location.reload());
            } catch (error) {
                console.error('Error deleting file: ', error);
            }
        }
    };

    const handleDownload = async (file) => {
        try {
            await fetchBlob(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/attachment/download?path=${file.path}`, {
                method: 'GET',
            }).then(async blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
        } catch (error) {
            console.error('Error downloading file: ', error);
        }
    };

    return (
        <div>
            <Modal isOpen={true} toggle={onClose} className="modal-fullscreen">
                <ModalHeader toggle={onClose}>Ficheros adjuntos</ModalHeader>
                <ModalBody>
                    {loading ? <Spinner/> : (
                        <ListGroup>
                            {files?.map((file, index) => (
                                <ListGroupItem key={file.path + '_' + index}
                                               className="d-flex justify-content-between align-items-center">
                                    {file.filename}
                                    <ButtonGroup>
                                        <Button size="sm" color="primary"
                                                onClick={() => handleDownload(file)}>Descargar</Button>
                                        <Button size="sm" color="danger"
                                                disabled={file.readOnly}
                                                onClick={() => handleDelete(file.path)}>Eliminar</Button>
                                    </ButtonGroup>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    )}
                    <div className="mt-3">
                        <Input type="file" multiple onChange={handleFileChange}/>
                        {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
                        <Button color="success" disabled={!selectedFiles} onClick={handleUpload}>Subir</Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

Attachment.propTypes = {
    entity: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired
};

export default Attachment;
