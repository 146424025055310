import {useFetchWithTokenRefresh} from "./hook/UseFetchWithTokenRefresh";
import {useCallback} from "react";

function doErrorAlert(errorMsg) {
    if (errorMsg === '"ERR_CANNOT_SET_SOLD_STATUS"') {
        errorMsg = 'No se puede cambiar el estado a VENDIDO. El número de clientes no coincide con el número total de personas.'
    }
    window.alert('Ha habido un error: ' + errorMsg);
}

export async function handleResponse(response) {
    if (response.status === 400) {
        let errorMsg = await response.text();
        doErrorAlert(errorMsg);
    } else if (response.status === 401 || response.status === 403) {
        localStorage.setItem('authentication_failure', 'true');
        window.location.href = '/logout';
    } else if (response.status !== 200) {
        doErrorAlert(response);
    } else if (response.headers.get('Content-Type')?.includes('application/json')) {
        return await response.json();
    }
    return null;
}

export function addToken(options) {
    // Check if accessToken is available in localStorage
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        // If accessToken is available, add Authorization header to options
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${accessToken}`,
        };
    }
}

export const useFetchData = () => {
    const fetchWithRetry = useFetchWithTokenRefresh();

    return useCallback(async (url, options) => {
        options = {
            ...options,
            credentials: 'include',
        };
        options.headers = {
            ...options.headers,
            'X-Requested-With': 'XMLHttpRequest',
        };
        addToken(options);

        return fetchWithRetry(url, options)
            .then(response => handleResponse(response));
    }, [fetchWithRetry]);
};

export const useFetchBlob = () => {
    const fetchWithRetry = useFetchWithTokenRefresh();

    return useCallback(async (url, options) => {
        options = {
            ...options,
            credentials: 'include',
        };
        options.headers = {
            ...options.headers,
            'X-Requested-With': 'XMLHttpRequest',
        };
        addToken(options);

        return await fetchWithRetry(url, options)
            .then(async response => {
                await handleResponse(response);
                return await response.blob();
            });
    }, [fetchWithRetry]);
};

export function simpleFetchData(url, options) {
    options = {
        ...options,
        credentials: 'include',
    };
    options.headers = {
        ...options.headers,
        'X-Requested-With': 'XMLHttpRequest',
    };

    return fetch(url, options);
}
