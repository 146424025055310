// AuthContext.js
import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from "prop-types";
import {auth, googleAuthProvider, signInWithPopup} from './FirebaseConfig';
import {handleResponse, simpleFetchData} from "../http/HttpUtil";

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const storedAuthenticated = localStorage.getItem('authenticated') === 'true';
    const [authenticated, setAuthenticated] = useState(storedAuthenticated);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    const handleLoginSuccess = useCallback((navigate, username) => {
        localStorage.setItem('authenticated', 'true');
        localStorage.setItem('user', username);
        setAuthenticated(true);
        setUser(username);
        navigate('/');
    }, [setAuthenticated, setUser]);

    const handleLogout = useCallback((navigate) => {
        localStorage.setItem('authenticated', 'false');
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setAuthenticated(false);
        setUser(null);
        navigate('/');
    }, [setAuthenticated, setUser]);

    const googleSignIn = useCallback(async (navigate) => {
        const authorize = (token, refreshToken, email) => {
            simpleFetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/authorize-google`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(() => {
                localStorage.setItem('accessToken', token);
                localStorage.setItem('refreshToken', refreshToken);
                handleLoginSuccess(navigate, email);
            }).catch(error => {
                console.error('Error authorizing via Google Sign in:', error);
                handleLogout(navigate);
            });
        };

        try {
            await signInWithPopup(auth, googleAuthProvider)
                .then((result) => {
                    const user = result.user;
                    const token = user?.accessToken;
                    const refreshToken = user.refreshToken;
                    const email = user?.email;
                    authorize(token, refreshToken, email);
                });
        } catch (error) {
            console.error('Failed to sign in with Google:', error);
            handleLogout(navigate);
        }
    }, [handleLoginSuccess, handleLogout]);

    const refreshAccessToken = useCallback(async (navigate) => {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            throw new Error('No refresh token found. User needs to sign in again.');
        }

        try {
            return simpleFetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/refresh-token-google`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({refreshToken}),
            }).then(response => handleResponse(response))
                .then(newToken => {
                    localStorage.setItem('accessToken', newToken);
                    return newToken;
                });
        } catch (error) {
            console.error('Failed to refresh access token:', error);
            handleLogout(navigate);
            return null;
        }
    }, [handleLogout]);

    const login = useCallback(async (username, password, navigate) => {
        try {
            const loginData = new URLSearchParams();
            loginData.append('username', username);
            loginData.append('password', password);
            const response = await simpleFetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                mode: 'cors',
                body: loginData,
            });

            if (response.ok) {
                // Successful login
                handleLoginSuccess(navigate, username);
            } else {
                console.error('Login failed: ', response.statusText);
                handleLogout(navigate);
            }
        } catch (error) {
            console.error('Error during login:', error);
            handleLogout(navigate);
        }
    }, [handleLoginSuccess, handleLogout]);

    const logout = useCallback(async (navigate) => {
        try {
            const response = await simpleFetchData(`${process.env.REACT_APP_BACKEND_BASE_URL}/logout`, {
                method: 'POST',
            });

            if (response.ok) {
                handleLogout(navigate);
            } else {
                console.error('Logout failed: ', response.statusText);
                handleLogout(navigate);
            }
        } catch (error) {
            console.error('Error during logout:', error);
            handleLogout(navigate);
        }
    }, [handleLogout]);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(storedUser);
        }
        setLoading(false);
    }, []);

    const contextValue = useMemo(() => {
        return {authenticated, user, login, logout, googleSignIn, refreshAccessToken};
    }, [authenticated, user, login, logout, googleSignIn, refreshAccessToken]);

    return (
        <AuthContext.Provider value={contextValue}>
            {loading ? null : children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
